import React from "react"
import ReactPlayer from "react-player"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Video = () => {
  const lang = "fo"

  return (
    <Layout lang={lang}>
      <SEO title="Høgra - Video" />
      <section className="video-page">
        <div className="container">
          <div className="embed-container">
            <ReactPlayer
              url="https://player.vimeo.com/progressive_redirect/playback/924426532/rendition/1080p/file.mp4?loc=external&log_user=0&signature=abb2ebb5767169d8c4d823f1cfebd587e8c2d1c771b810534d2a01e89e25ae06"
              width="100%"
              heigth="100%"
              playing={true}
              controls={true}
              muted={true}
              loop={true}
              playsinline
            />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Video
